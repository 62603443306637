import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import type { TagPagesAndParams } from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type { Tag } from '@aurora/shared-generated/types/graphql-schema-types';
import { NodeType } from '@aurora/shared-types/nodes/enums';
import {
  EndUserComponent,
  EndUserPages,
  EndUserPathParams,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import React, { useContext } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import type { ItemType, TagViewVariant } from '../../../../types/enums';
import type { ItemViewVariantFC } from '../../../entities/types';
import useTranslation from '../../../useTranslation';
import localStyles from './TagViewChip.module.pcss';

/**
 * Tag View - Chip
 *
 * @author Jonathan Bridges, Willi Hyde
 */
const TagViewChip: ItemViewVariantFC<Tag, ItemType.TAG, TagViewVariant.CHIP> = ({
  entity: tag,
  className,
  usePostCount = false
}) => {
  const cx = useClassNameMapper(localStyles);
  const { Link } = useEndUserRoutes();
  const { contextNode } = useContext(AppContext);

  const { formatMessage, loading: textLoading } = useTranslation(EndUserComponent.TAG_VIEW_CHIP);

  const queryParams =
    contextNode.nodeType !== NodeType.COMMUNITY
      ? {
          [EndUserQueryParams.NODE_ID]: contextNode.id
        }
      : {};

  if (textLoading) {
    return null;
  }

  return (
    <Link<TagPagesAndParams>
      route={EndUserPages.TagPage}
      params={{ [EndUserPathParams.TAG_NAME]: tag.text }}
      query={queryParams}
    >
      <a
        className={cx('lia-tag', className)}
        data-testid="TagText"
        aria-label={formatMessage('tagLabelName', { tagName: tag.text })}
      >
        <span
          className={cx('lia-tag-text', { 'lia-g-divider': usePostCount })}
          data-testid="TagView.TagViewChip.TagText"
        >
          {tag.text}
        </span>
        {usePostCount && (
          <span className={cx('lia-tag-item lia-g-divider')}>{tag.messagesCount}</span>
        )}
      </a>
    </Link>
  );
};
export default TagViewChip;
